const initialState = {
  auth: {
    cognitoIdToken: null,
    validUser: false,
    user: {
      userId: '',
      email: '',
      userName: '',
    },
  },
  feedbackPending: false,
  feedbackError: null,
};

export default initialState;
