import React from 'react';
import { useGetAuthrization } from './redux/hooks';
import { redirectUrl } from '../../common/apiConfig';
import { datadogRum } from '@datadog/browser-rum';
export default function App({ children }) {
  const { auth } = useGetAuthrization();

  if(!auth.cognitoIdToken) {
    window.location.assign(redirectUrl);
  }
  if(auth && auth.user){
    datadogRum.setUser({
      ...auth.user
    })
  }
  return (
    <div className="home-app">
      <div className="page-container">{children}</div>
    </div>
  );
}
