// Initial state is the place you define all initial values for the Redux store of the feature.
// In the 'standard' way, initialState is defined in reducers: http://redux.js.org/docs/basics/Reducers.html
// But when application grows, there will be multiple reducers files, it's not intuitive what data is managed by the whole store.
// So Rekit extracts the initial state definition into a separate module so that you can have
// a quick view about what data is used for the feature, at any time.

// NOTE: initialState constant is necessary so that Rekit could auto add initial state when creating async actions.
const initialState = {
  substitutionsHeaderEntity: {
    site: null,
    siteDesc: '',
    nationalId: null,
    nationalName: '',
    customerNumber: null,
    customerName: '',
    ruleType: '',
    originalItem: null,
    originalItemDescription: '',
    originalPack: '',
    originalSize: '',
    originalStorageCode: '',
    originalUom: '',
    originalBrand: '',
    originalItemCategory: '',
    originalSplitIndicator: '',
    substituteItem: null,
    substituteItemDescription: '',
    substitutePack: '',
    substituteSize: '',
    substituteStorageCode: '',
    substituteUom: '',
    substituteSplitIndicator: null,
    substituteBrand: '',
    endDate: null,
    subRatio: '',
    createTimestamp: null,
    createdById: null,
    createdByName: '',
    modifiedTimestamp: null,
    modifiedById: null,
    modifiedByName: '',
    expandedItem: null,
  },
  expandedItemEntity: {
    createTimestamp: null,
    createdById: null,
    createdByName: null,
    endDate: null,
    modifiedById: null,
    modifiedByName: null,
    modifiedTimestamp: null,
    reasonCode: null,
    reasonDescription: null,
    subPriority: null,
    subRatio: null,
    substituteBrand: null,
    substituteItem: null,
    substituteItemDescription: null,
    substitutePack: null,
    substituteSize: null,
    substituteStorageCode: null,
    substituteUom: null,
    ruleType: true,
  },
  substitutionsData: null,
  fetchSubstitutionsDataPending: false,
  fetchSubstitutionsDataError: null,
  searchConditionData: null,
  conditionData: null,
  validationItemData: null,
  fetchValidationItemDataPending: false,
  fetchValidationItemDataError: null,
  conceptData: null,
  fetchConceptDataPending: false,
  fetchConceptDataError: null,
  outsData: null,
  fetchOutsDataPending: false,
  fetchOutsDataError: null,
  dropoutsData: null,
  fetchDropoutsDataPending: false,
  fetchDropoutsDataError: null,
  orderDetail: null,
  fetchOrderDetailDataPending: false,
  fetchOrderDetailDataError: null,
  inventoryData: null,
  fetchInventoryDataPending: false,
  fetchInventoryDataError: null,
  validationCustomerData: null,
  fetchValidationCustomerDataPending: false,
  fetchValidationCustomerDataError: null,
  updateSubstitutionsDataPending: false,
  updateSubstitutionsDataError: null,
  exportOutsDataPending: false,
  exportOutsDataError: null,
  fetchAddedSubsDataPending: false,
  fetchAddedSubsDataError: null,
  downloadSubstitutionsFilePending: false,
  downloadSubstitutionsFileError: null,
  accountData: null,
  fetchAccountDataPending: false,
  fetchAccountDataError: null,
  deleteAccountDataPending: false,
  deleteAccountDataError: null,
  updateAccountDataPending: false,
  updateAccountDataError: null,
  checkAccountIfExistPending: false,
  checkAccountIfExistError: null,
  addedAccountData: null,
  exportAccountsDataPending: false,
  exportAccountsDataError: null,
  uploadAccountsPending: false,
  uploadAccountsError: null,
  uploadSubstitutionsPending: false,
  uploadSubstitutionsError: null,
  fetchRecommendedProductsPending: false,
  fetchRecommendedProductsError: null,
  recommendedProducts: null,
  searchSubstituteProductsPending: false,
  searchSubstituteProductsError: null,
  substituteProducts: null,
  removeDropOutsPending: false,
  removeDropOutsError: null,
  alertsError:null,
  alertsPending:false,
  removeAllocatedItemPending:false,
  removeAllocatedItemError:null,
  allocateOriginalItemPending: false,
  allocateOriginalItemError: null,
  checkOutsLockPending: false,
  checkOutsLockError: null,
  updateOutsRecordLockPending: false,
  updateOutsRecordLockError: null,
  deleteManageSubsPending: false,
  deleteManageSubsError: null,
  checkEditSubsLockPending: false,
  checkEditSubsLockError: null,
  updateEidtSubsLockPending: false,
  updateEidtSubsLockError: null,
  checkEditAccountLockPending: false,
  checkEditAccountLockError: null,
  updateEditAccountLockPending: false,
  updateEditAccountLockError: null,
  fetchSearchPanelOrgInfoPending: false,
  fetchSearchPanelOrgInfoError: null,
  originalList: [],
  originalConstData: {
    market: [],
    region: [],
    sites: [],
  },
  initTerritoryFilters:null,
  validateItemInChargePending: false,
  validateItemInChargeError: null,
  orgDropdownList: {
    market: [],
    region: [],
    sites: [],
  },
  enabledSites:[],
  fetchEnabledSitesPending: false,
  fetchEnabledSitesError: null,
  isCorpProfileUser: false,
  getSubstitutionsDataPending: false,
  getSubstitutionsDataError: null,
  validateSubItemPending: false,
  validateSubItemError: null,
  validateAgentPending: false,
  validateAgentError: null,
  getAllAgentsPending: false,
  getAllAgentsError: null,
  saveAndEmailPending: false,
  saveAndEmailError: null,
  fetchCustomerGroupDataPending: false,
  fetchCustomerGroupDataError: null,
  customerGroupData: null,
  updateCustomerGroupPending: false,
  updateCustomerGroupError: null,
  groupIdsData: null,
  fetchGroupIdsDataPending: false,
  fetchGroupIdsDataError: null,
  fetchValidateGroupPending: false,
  fetchValidateGroupError: null,
  customerData: null,
  fetchCustomerDataPending: false,
  fetchCustomerDataError: null,
  validateCustomerPending: false,
  validateCustomerError: null,
  addCustomersPending: false,
  addCustomersError: null,
  removeCustomerPending: false,
  removeCustomerError: null,
  uploadGroupsPending: false,
  uploadGroupsError: null,
  exportGroupsDataPending: false,
  exportGroupsDataError: null,
  fetchDropOutsReasonPending: false,
  fetchDropOutsReasonError: null,
  dropOutsReasonData: [],
  checkIfExists4thSubRulePending: false,
  checkIfExists4thSubRuleError: null,
  validateActiveConceptCusPending: false,
  validateActiveConceptCusError: null,
  deleteCustomerGroupPending: false,
  deleteCustomerGroupError: null,
  fetchAccountsAuditTrailDataPending: false,
  fetchAccountsAuditTrailDataError: null,
  accountsAuditData: null,
  removeCustomersPending: false,
  removeCustomersError: null,
  fetchSubsAuditTrailDataPending: false,
  fetchSubsAuditTrailDataError: null,
  subsAuditTrailData: null,
};

export default initialState;
