import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
import { Menu } from "antd";
import { CONST_MAINTAIN_ACCOUNTS_PATH_NAME,
  CONST_MANAGE_OUTS_PATH_NAME,
  CONST_MANAGE_SUBS_PATH_NAME,
  CONST_BOS_ROOT_PATH_NAME,
  CONST_CUSTOMER_GROUP_PATH_NAME,
  CONST_MANAGE_ACCOUNTS_AUDIT_TRAIL_PATH_NAME,
  CONST_SUB_REPOSITORIES_AUDIT_TRAIL_PATH_NAME,
   } from '../../common/manageSubsConstants';
import { useResetSubstitutionsData, useResetSearchConditionData, useResetOutsData, useResetAccountData, useRetainConditionData } from './redux/hooks';
import { useSelector } from 'react-redux';

export default function ManageSubsNavigation() {
  const { territoryData, conditionData } = useSelector(state => ({
    territoryData: state.dashboard.territoryData,
    conditionData: state.manageSubs.conditionData
  }))
  const location = useLocation();
  const { resetSubstitutionsData } = useResetSubstitutionsData();
  const { resetSearchConditionData } = useResetSearchConditionData();
  const { resetOutsData } = useResetOutsData();
  const { resetAccountData } = useResetAccountData();
  const [ selectedKeys, setSelectedKeys ] = useState(CONST_MANAGE_OUTS_PATH_NAME);
  const { retainConditionData } = useRetainConditionData();
  
  useEffect(() => {
    const objCurrPath = location.pathname;
    //fixed refresh to render wrong menu tab issue
    if(objCurrPath.length > 0 && objCurrPath !== selectedKeys) {
      if(objCurrPath === CONST_BOS_ROOT_PATH_NAME) {
        setSelectedKeys(CONST_MANAGE_OUTS_PATH_NAME);
      } else {
        setSelectedKeys(objCurrPath);
      }
    }
  }, [selectedKeys]); //eslint-disable-line

  useEffect(() => {
    //defect: if current menu is not manage outs, then click manage-subs menu will show last menu and manage out page
    const objCurrPath = location.pathname;
    if(objCurrPath.length > 0 && objCurrPath === CONST_BOS_ROOT_PATH_NAME) {
      //reset all data if switch menu
      resetSearchConditionData();
      resetSubstitutionsData();
      resetOutsData();
      resetAccountData();
      setSelectedKeys(CONST_MANAGE_OUTS_PATH_NAME);
    }
  }, [location.pathname]); //eslint-disable-line
  
  const onMenuClick = ({ key, keyPath, domEvent }) => {
    resetSearchConditionData();
    resetSubstitutionsData();
    resetOutsData();
    resetAccountData();
    setSelectedKeys(key);
    if((key === CONST_MANAGE_SUBS_PATH_NAME || key === CONST_MAINTAIN_ACCOUNTS_PATH_NAME) && 
      conditionData && conditionData.territoryInfoList && Array.isArray(conditionData.territoryInfoList)) {
      retainConditionData({...conditionData, territoryInfoList: handleTerritoryForSave(conditionData.territoryInfoList)});
    }
  }

  const handleTerritoryForSave = (selectedData) => {
    let ret = []
    if(selectedData && selectedData.length > 0 && territoryData && territoryData.territories) {
      selectedData.forEach(name => {
        const currTerritoryData = territoryData.territories.filter(item => item.terrName === name);
        if(currTerritoryData.length > 0) {
          ret.push(name)
        }
      })
    }
    return ret
  }

  return (
    <div className="manage-subs-manage-subs-navigation">
      <Menu
        className="menu-list"
        mode="inline"
        onClick={onMenuClick}
        selectedKeys={[selectedKeys]}
        style={{ width: 180 }}
      >
        <Menu.Item key={CONST_MAINTAIN_ACCOUNTS_PATH_NAME} className='font-size-14 menu-item'>
          <Link to={CONST_MAINTAIN_ACCOUNTS_PATH_NAME}>Manage Accounts</Link>
        </Menu.Item>
        <Menu.Item key={CONST_MANAGE_OUTS_PATH_NAME} className='font-size-14 menu-item'>
          <Link to={CONST_MANAGE_OUTS_PATH_NAME}>Manage Outs</Link>
        </Menu.Item>
        <Menu.Item key={CONST_MANAGE_SUBS_PATH_NAME} className='font-size-14 menu-item'>
          <Link to={CONST_MANAGE_SUBS_PATH_NAME}>Sub Repositories</Link>
        </Menu.Item>
        {/* <Menu.Item key={CONST_ADMIN_CONSOLE_PATH_NAME} className='font-size-14 menu-item'>
          <Link to={CONST_ADMIN_CONSOLE_PATH_NAME}>Admin Console</Link>
        </Menu.Item> */}
         <Menu.Item key={CONST_CUSTOMER_GROUP_PATH_NAME} className='font-size-14 menu-item'>
          <Link to={CONST_CUSTOMER_GROUP_PATH_NAME}>Create Groups</Link>
        </Menu.Item>
        <Menu.Item key={CONST_MANAGE_ACCOUNTS_AUDIT_TRAIL_PATH_NAME} className='font-size-14 menu-item menu-word-break'>
          <Link to={CONST_MANAGE_ACCOUNTS_AUDIT_TRAIL_PATH_NAME}>Manage Accounts Audit Trail</Link>
        </Menu.Item>
        <Menu.Item key={CONST_SUB_REPOSITORIES_AUDIT_TRAIL_PATH_NAME} className='font-size-14 menu-item menu-word-break'>
          <Link to={CONST_SUB_REPOSITORIES_AUDIT_TRAIL_PATH_NAME}>Sub Repositories<br/>Audit Trail</Link>
        </Menu.Item>
      </Menu>
    </div>
  );
};
