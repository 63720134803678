import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import decode from 'jwt-decode';
import { REHYDRATE } from 'redux-persist/lib/constants';
import { HOME_GET_AUTHRIZATION } from './constants';

export function getAuthrization(auth) {
  return {
    type: HOME_GET_AUTHRIZATION,
    data: auth,
  };
}

export function useGetAuthrization() {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.home.auth);

  const updateAuthrization = useEffect(() => {
    if (window.location.href.indexOf('id_token') > -1) {
      let newAuth = JSON.parse(JSON.stringify(auth));
      const idToken = window.location.href.match(/(id_token)\=([\S\s]*?)\&/)[2]; //eslint-disable-line
      const decodeToken = decode(idToken);
      const email = decodeToken.email;
      const name = decodeToken['custom:networkid'];
      const userId = name && name.slice(0, name.indexOf('@'));
      const userName = email && email.slice(0, email.indexOf('@'));
      newAuth.cognitoIdToken = idToken;
      newAuth.validUser = true;
      const newUserId = userId?userId:userName;
      newAuth.user = {
        email,
        userId:newUserId,
        userName,
      };
      dispatch(getAuthrization(newAuth));
    }
  }, [dispatch, window.location.href.indexOf('id_token')]); //eslint-disable-line

  return { auth, updateAuthrization };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_GET_AUTHRIZATION:
      return {
        ...state,
        auth: action.data,
      };
    case REHYDRATE:
      const payloadData = action.payload;
      if (payloadData) {
        const authData = payloadData.home;
        if(authData.auth) {
          return {
            ...state,
            auth: authData.auth,
          };
        }
      }
      return state;
    default:
      return state;
  }
}
